<template>
  <v-form
    ref="form"
    class="rcc-users-form"
    @submit.prevent="handleFormSubmit"
    v-model="valid"
  >
    <div class="rcc-users-form__form-body">
      <rcc-text-input
        label="Имя"
        width="40%"
        v-model="form.fio"
        :rules="[value => isRequiredField({ value, field: 'Имя' })]"
      />

      <rcc-text-input
        label="Логин"
        width="40%"
        v-model="form.login"
        is-lower-case
        :rules="[value => isRequiredField({ value, field: 'Логин' })]"
      />

      <rcc-select
        v-model="form.role"
        label="Роль"
        width="40%"
        :items="rolesOptions"
        showBy="role"
        choose-by="id"
        :rules="[value => isRequiredField({ value, errorText: 'Выберите роль' })]"
      />

      <rcc-autocomplete
        v-if="form.role === $constants.users.supplier"
        v-model="form.supplier_id"
        width="40%"
        label="Поставщик"
        show-by="name"
        :items="suppliersOptions"
        :rules="[value => isRequiredField({ value, errorText: 'Выберите поставщика' })]"
      />

      <rcc-text-input
        label="email"
        width="40%"
        v-model="form.email"
        :rules="[value => isRequiredField({ value, field: 'email' }), emailValidation]"
      />
    </div>

    <rcc-confirm-dialog
      :is-show.sync="isShowConfirmDialog"
      :title="confirmTitle"
      :text="confirmText"
      :confirmCallback="confirmCallback"
    />

    <rcc-footer-buttons
      :danger-text="dangerButtonText"
      :waningText="warningButtonText"
      :is-submit="isSubmit"
      @cancel-click="$router.push({ path: '/', name: 'users' })"
      @warning-click="handleBlockUserClick"
      @danger-click="handleResetPasswordClick"
    />
  </v-form>
</template>

<script>
import Page from '@/mixins/page'
import Form from '@/mixins/form'
import { required } from 'vuelidate/lib/validators'

import RccTextInput from 'Components/controls/rcc-text-input'
import RccSelect from 'Components/controls/rcc-select'
import RccFooterButtons from 'Components/layouts/rcc-footer-buttons'
import RccConfirmDialog from 'Components/dialogs/rcc-confirm-dialog'
import RccAutocomplete from 'Components/controls/selects/autocomplete'

const STATUSES_OPTIONS = [
  { id: 'active', status: 'Активен' },
  { id: 'blocked', status: 'Заблокирован' }
]

const ROLES_OPTIONS = [
  { id: 1, role: 'Администратор' },
  { id: 2, role: 'Менеджер' },
  { id: 3, role: 'Пользователь' },
  { id: 4, role: 'Поставщик' }
]

export default {
  components: {
    RccTextInput,
    RccSelect,
    RccFooterButtons,
    RccConfirmDialog,
    RccAutocomplete
  },

  mixins: [Page, Form],

  data() {
    return {
      valid: true,
      isNew: true,
      dangerButtonText: '',
      isShowConfirmDialog: false,
      confirmTitle: '',
      confirmText: '',
      confirmCallback: null,
      suppliersOptions: [],

      form: {
        disabled_at: null,
        fio: '',
        login: null,
        role: 1,
        email: null,
        supplier_id: null
      }
    }
  },

  validations: {
    form: {
      fio: { required },
      login: { required },
      role: { required },
      email: { required }
    }
  },

  computed: {
    statusesOptions() {
      return STATUSES_OPTIONS
    },

    rolesOptions() {
      return ROLES_OPTIONS
    },

    warningButtonText() {
      return ''
    }
  },

  mounted() {
    this.getSuppliers()
  },

  methods: {
    emailValidation(value) {
      const regexp = /.+@.+\..+/i
      return regexp.test(value) || 'Неверный email'
    },

    handleBlockUserClick() {
      return
    },

    handleResetPasswordClick() {
      return
    },

    resetUserPassword() {
      return
    },

    getSuppliers() {
      this.$apiMethods.suppliers.allList()
        .then(({ items }) => {
          this.suppliersOptions = items
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.rcc-users-form {
  height: 100%;
  display: flex;
  flex-direction: column;

  &__form-body{
    flex-grow: 1;
    padding: $base-form-padding;
  }
}
</style>
